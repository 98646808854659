import { injectIntl } from "gatsby-plugin-intl"
import React from "react"

const Contact = ({intl}) => (
    <section className="contact" id="contact">
        <div className="container">
          <div className="row reversed">
            <div className="col-lg-6">
                <h2 className="title">{intl.formatMessage({ id: "contact.title" })}</h2>
                <h1>{intl.formatMessage({ id: "contact.header" })}</h1>
                <p>{intl.formatMessage({ id: "contact.intro" })}</p>
                <ul className="list-icons">
                    <li>
                      <div className="icon icon-pin"></div>
                      {intl.formatMessage({ id: "contact.address.street" })}
                    </li>
                    <li>
                      <div className="icon icon-telephone"></div>
                      {intl.formatMessage({ id: "contact.address.phone" })}
                    </li>
                    <li>
                      <div className="icon icon-email"></div>
                      {intl.formatMessage({ id: "contact.address.email" })}
                    </li>
                    <li>
                      <div className="icon icon-vat-id"></div>
                      {intl.formatMessage({ id: "contact.address.vatId" })}
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
              <iframe title="Map" width="523" height="550" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA8-sa0y8nhWR4zwDLtD2ch_bHpqsgh5Os&q=Datext%20Wegiera&zoom=17" frameBorder="0" scrolling="no"></iframe>
            </div>
          </div>
        </div>
    </section>
)

export default injectIntl(Contact)
