import React from "react"

const images = require.context('../images/', true);

const LanguageItem = ({ code, language }) => (

    <div className="language">
        <div className="flag">
          <img src={images('./' + code+'.svg')} width="30" height="30" alt="" />
        </div>
        {language}
    </div>
)

export default LanguageItem
