import { useIntl, injectIntl } from "gatsby-plugin-intl"
import React from "react"
import offerPicture from '../images/offer.svg';

import Modal from 'react-bootstrap/Modal'

function ButtonAndModal() {
  const intl = useIntl()

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="btn" onClick={handleShow}>
        <span className="full-width">{intl.formatMessage({ id: "offer.button" })}</span>
        <span className="short-width">{intl.formatMessage({ id: "offer.buttonShort" })}</span>
      </button>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="full-width">{intl.formatMessage({id:"offer.modalTitle"})}</Modal.Title>
          <Modal.Title className="short-width">{intl.formatMessage({id:"offer.modalTitleShort"})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
              <ul className="list-icons">
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l1"})}</li>
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l2"})}</li>
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l3"})}</li>
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l4"})}</li>
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l5"})}</li>
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l6"})}</li>
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l7"})}</li>
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l8"})}</li>
                <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l9"})}</li>
                </ul>
              </div>
              <div className="col-md-6">
              <ul className="list-icons">
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l10"})}</li>
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l11"})}</li>
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l12"})}</li>
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l13"})}</li>
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l14"})}</li>
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l15"})}</li>
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l16"})}</li>
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l17"})}</li>
              <li><div className="icon icon-tick"></div> {intl.formatMessage({id:"offer.list.l18"})}</li>
              </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Offer = ({intl}) => (
    <section className="offer" id="offer">
      <div className="container">
        <div className="row reversed">
          <div className="col-lg-6">
              <h2 className="title">{intl.formatMessage({ id: "offer.title" })}</h2>
              <h1>{intl.formatMessage({ id: "offer.header" })}</h1>
              <ul className="list-icons">
                  <li>
                    <div className="icon icon-tick"></div>
                    {intl.formatMessage({ id: "offer.item1" })}
                  </li>
                  <li>
                    <div className="icon icon-tick"></div>
                    {intl.formatMessage({ id: "offer.item2" })}
                  </li>
                  <li>
                    <div className="icon icon-tick"></div>
                    {intl.formatMessage({ id: "offer.item3" })}
                  </li>
              </ul>
              <ButtonAndModal />
          </div>
          <div className="col-lg-6">
              <img src={offerPicture} alt=""/>
          </div>
        </div>
      </div>
    </section>
)

export default injectIntl(Offer)
