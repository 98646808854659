import { injectIntl} from "gatsby-plugin-intl"
import React from "react"
import aboutPicture from '../images/about.jpg';

const About = ({intl}) => (
    <section className="about colored" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
                <h2 className="title">{intl.formatMessage({ id: "about.title" })}</h2>
                <h1>{intl.formatMessage({ id: "about.header" })}</h1>
                <p>{intl.formatMessage({ id: "about.text" })}</p>
            </div>
            <div className="col-lg-6">
                <img src={aboutPicture} alt=""/>
            </div>
          </div>
        </div>
    </section>
)

export default injectIntl(About)
