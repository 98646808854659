import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import Review from '../components/review';
import Carousel from 'react-bootstrap/Carousel';
import reviews from '../data/reviews.json';

const reviewsItems = reviews.map((row, index) =>
    <Carousel.Item key={index}>
      <Review review={row} />
    </Carousel.Item>
);

function ControlledCarousel() {
  const [index, setIndex] = React.useState(0);
  const [direction, setDirection] = React.useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  return (
    <Carousel activeIndex={index} direction={direction} onSelect={handleSelect} controls={false}>
      {reviewsItems}
    </Carousel>
  );
}

const Reviews = ({intl}) => (
  <section className="reviews colored" id="reviews">
      <div className="container">
      <h2 className="title center">{intl.formatMessage({ id: "reviews.title" })}</h2>
        <div className="row">
            <ControlledCarousel />
        </div>
      </div>
  </section>
);

export default injectIntl(Reviews);
