import { injectIntl} from "gatsby-plugin-intl"
import React from "react"
import heroPicture from '../images/hero.png';
import animateScrollTo from 'animated-scroll-to';
import SendInquiry from '../components/send-inquiry'

class Hero extends React.Component {
  aboutButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();

    let offset = -88;

    animateScrollTo(document.querySelector('#about'), {verticalOffset: offset, maxDuration: 600});
  }

  render() {
    return (
      <section className="hero" id="hero">
          <div className="container">
            <div className="row">
              <div className="content">
                <h1>{this.props.intl.formatMessage({ id: "hero.title" })}</h1>
                <p className="intro">{this.props.intl.formatMessage({ id: "hero.intro" })}</p>
                <SendInquiry/>
                <button className="btn white" onClick={this.aboutButtonClick}>{this.props.intl.formatMessage({id: "actions.about"})}</button>
              </div>
              <img src={heroPicture} alt="" className="heroPicture" />
            </div>
          </div>
      </section>
    )
  }
}

export default injectIntl(Hero)
