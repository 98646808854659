import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import papersPicture from '../images/papers.svg';
import SendInquiry from '../components/send-inquiry'

const Pricing = ({intl}) => (
    <section className="pricing" id="pricing">
        <div className="container">
            <h2 className="title center">{intl.formatMessage({ id: "pricing.title" })}</h2>
            <h1 className="center">{intl.formatMessage({ id: "pricing.header" })}</h1>
            <div className="row">
              <div className="col-lg-6">
                  <ul className="list-icons">
                      <li>
                        <div className="icon icon-speed"></div>
                        {intl.formatMessage({ id: "pricing.item1" })}
                      </li>
                      <li>
                        <div className="icon icon-pricetag"></div>
                        {intl.formatMessage({ id: "pricing.item2" })}
                      </li>
                      <li>
                        <div className="icon icon-government"></div>
                        {intl.formatMessage({ id: "pricing.item3" })}
                      </li>
                  </ul>
              </div>
              <div className="col-lg-6 center">
                <img src={papersPicture} alt="" />
                <SendInquiry />
                <p className="sidenote">{intl.formatMessage({ id: "pricing.or" })}</p>
              </div>
          </div>
        </div>
    </section>
)

export default injectIntl(Pricing)
