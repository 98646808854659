import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../sections/hero";
import Intro from "../sections/intro";
import Offer from "../sections/offer";
import About from "../sections/about";
import Pricing from "../sections/pricing";
import Reviews from "../sections/reviews";
import Contact from "../sections/contact";

const IndexPage = () => {
  const intl = useIntl()

  return (
      <Layout>
        <SEO
            lang={intl.locale}
            title={intl.formatMessage({ id: "title" })}
            keywords={[`Dariusz Wegiera`, `Tłumacz przysięgły Stargard`, `Tłumacz niemiecki`]}
        />
          <Hero />
          <Intro />
          <Offer />
          <About />
          <Pricing />
          <Reviews />
          <Contact />
      </Layout>
  )
}

/*

* */

export default IndexPage
