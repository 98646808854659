import { injectIntl } from "gatsby-plugin-intl"
import React from "react"

import earthIcon from '../images/earth.svg';
import LanguageItem from "../components/language-item";

const Intro = ({intl}) => (
    <section className="intro colored">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
                <h3>{intl.formatMessage({ id: "intro.languages.title" })}</h3>
                  <ul className="languages row">
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="de" language={`${intl.formatMessage({ id: "intro.languages.german" })}`} /></li>
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="en" language={`${intl.formatMessage({ id: "intro.languages.english" })}`} /></li>
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="da" language={`${intl.formatMessage({ id: "intro.languages.danish" })}`} /></li>
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="sv" language={`${intl.formatMessage({ id: "intro.languages.swedish" })}`} /></li>
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="nl" language={`${intl.formatMessage({ id: "intro.languages.dutch" })}`} /></li>
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="no" language={`${intl.formatMessage({ id: "intro.languages.norwegian" })}`} /></li>
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="it" language={`${intl.formatMessage({ id: "intro.languages.italian" })}`} /></li>
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="fr" language={`${intl.formatMessage({ id: "intro.languages.french" })}`} /></li>
                    <li className="col-6 col-sm-4 col-lg-6 col-xl-4"><LanguageItem code="pl" language={`${intl.formatMessage({ id: "intro.languages.polish" })}`} /></li>
                  </ul>
                <div className="sidenote">
                  <div className="flag">
                     <img src={earthIcon} width="30" height="30" alt="" />
                  </div>
                  <div className="label">{intl.formatMessage({ id: "intro.languages.other" })}</div>
                </div>
            </div>
            <div className="col-lg-6">
              <h3>{intl.formatMessage({ id: "intro.cooperation.title" })}</h3>
              <p>{intl.formatMessage({ id: "intro.cooperation.answer" })}</p>
              <h3>{intl.formatMessage({ id: "intro.clients.title" })}</h3>
              <p>{intl.formatMessage({ id: "intro.clients.answer" })}</p>
            </div>
          </div>
        </div>
    </section>
)

export default injectIntl(Intro)
