import { injectIntl } from "gatsby-plugin-intl"
import React from "react"

class Review extends React.Component {
  render() {
    let localGuide;

    if (this.props.review.author_local_guide) {
      localGuide = <span className="color-orange">Lokalny przewodnik</span>;
    } else {
      localGuide = '';
    }

    return (
      <div className="review">
        <blockquote>{this.props.review.text}</blockquote>
        <div className="author">
          <div className="avatar"><img src={this.props.review.profile_photo_url} alt="" height="40" /></div>
          <div className="author">
            <b><a href={this.props.review.author_url} target="_blank" rel="noopener noreferrer">{this.props.review.author_name}</a></b>
            <p className="meta sidenote">
            {localGuide}<span>{this.props.review.author_reviews} opinii</span><span>{this.props.review.author_photos} zdjęć</span></p>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Review);
